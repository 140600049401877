import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const PrivacyPage = () => (
	<Layout>
		<SEO title="Согласие на обработку персональных данных" />
		<div className="container">
			<h1>Согласие на обработку персональных данных</h1>
			<PrivacyPageContent />
		</div>
	</Layout>
);

export const PrivacyPageContent = () => (
	<>
		<p>
			Я, даю свое согласие АНО «Стань чемпионом» на обработку моих персональных данных. Согласие касается фамилии,
			имени, отчества, данных о поле, дате рождении, гражданстве, типе документа, удостоверяющем личность (его
			серии, номере, дате и месте выдачи), а также сведений об опыте работы, месте работы и должности.
		</p>
		<p>
			Я даю согласие на использование персональных данных в мобильном приложении, а также на хранение всех
			вышеназванных данных на электронных носителях. Также данным согласием я разрешаю сбор моих персональных
			данных, их хранение, систематизацию, обновление, использование (в т.ч. передачу третьим лицам для обмена
			информацией), а также осуществление любых иных действий, предусмотренных действующим законом Российской
			Федерации.
		</p>
		<p>
			До моего сведения доведено, что АНО «Стань чемпионом» гарантирует обработку моих персональных данных в
			соответствии с действующим законодательством Российской Федерации. Срок действия данного согласия не
			ограничен. Согласие может быть отозвано в любой момент по моему письменному заявлению.
		</p>
	</>
);

export default PrivacyPage;
